const FooterEmpty = () => {

    return (
        <>         
 
        </>
    );
};

export default FooterEmpty;

