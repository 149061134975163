import { useLocation } from "react-router-dom";
import Footer from "../layouts/footer/Footer";
import FooterEmpty from "../layouts/footer/FooterEmpty";

const SetFooter = () => {
    var location = useLocation();

    if (location.pathname.includes("/login") || location.pathname.includes("/register")) {
        return (
            <FooterEmpty/>
        );

    }
    else {

        return (
            <Footer/>
        );
    }


};

export default SetFooter;
