import { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import  { ReCAPTCHA } from "react-google-recaptcha"

const Footer = () => {
    // State to hold form input values
    const [formData, setFormData] = useState({
        email: '',
    });

    const notify = () => toast.success('Vous êtes abonné à notre Newslater');
    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        notify();
        // You can perform your form submission logic here
        console.log('Form submitted with data:', formData);
    };

    return (
        <>

            <footer className="footer clearfix">
                <div className="container">
                    <div className="second-footer">
                        <div className="row">
                            <div className="col-md-4 widget-area">
                                <div className="widget clearfix">
                                    <h3 className="widget-title">A propos de MINAFRIKA</h3>
                                    <div className="widget-text">
                                        <p>MINE ET BTP</p>
                                        <div className="social-icons">
                                            <ul className="list-inline">
                                                <li><a href="#"><i className="fa fa-whatsapp"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                                <li><a href="#"><i className="fa fa-youtube-play"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 offset-md-1 widget-area">
                                <div className="widget clearfix usful-links">
                                    <h3 className="widget-title">Liens utiles</h3>
                                    <ul>
                                        <li><a href="/about">A propos</a></li>
                                        <li><a href="/service">Services</a></li>
                                        <li><a href="/project">Projets</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4 widget-area">
                                <div className="widget clearfix">
                                    <h3 className="widget-title">Abonnez-vous</h3>
                                    <p>Inscrivez-vous et nous vous enverrons une notification par courrier électronique.</p>
                                    <div className="widget-newsletter">
                                        <form onSubmit={handleSubmit}>
                                            <input type="email" placeholder="Adresse email" name="email"
                                                value={formData.email}
                                                onChange={handleInputChange} required />
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                            />
                                            <button type="submit">Envoyer</button>
                                            <Toaster
                                                position="top-right"
                                                reverseOrder={false}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottom-footer-text">
                        <div className="row copyright">
                            <div className="col-md-12">
                                <p className="mb-0">© {new Date().getFullYear()} - <a href="#">Copyright MINAFRIKA</a>. Tous droits réservés.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
};

export default Footer;
