import Preloader from "../../../components/Preloader";

const Service = () => {
    return (
        <>
        <Preloader />
            <section className="banner-header banner-img-top section-padding valign bg-img bg-fixed" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/slider/1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <h6>Ce que nous faisons</h6>
                    <h1>Nos <span>Services</span></h1>
                </div>
            </div>
        </div>
    </section>
    <section className="services2 center section-padding bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="square-flip">
                        <div className="square bg-img" style={{ backgroundImage: 'url(assets/img/services/1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                            <div className="square-container d-flex align-items-end justify-content-end">
                                <div className="box-title">
                                    <div><i className="norc-new-construction"></i></div>
                                    <h4>Nos services BTP</h4>
                                </div>
                            </div>
                            <div className="flip-overlay"></div>
                        </div>
                        <div className="square2">
                            <div className="square-container2">
                                <h4>Nos services BTP comprennent :</h4>
                                <ol className="number mb-30">
                                    <li><b>Bâtiment</b> </li>
                                    <li><b>Travaux publics</b> </li>
                                    <li><b>Génie civil</b> </li>
                                    <li><b>Rénovation</b></li>
                                    <li><b>Maintenance</b></li>
                                </ol>
                                
                                <a href="single-service/1" className="link-btn" tabIndex="0">En savoir plus</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="square-flip">
                        <div className="square bg-img" style={{ backgroundImage: 'url(assets/img/services/2.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className="square-container d-flex align-items-end justify-content-end">
                                <div className="box-title">
                                    <div><i className="norc-construction-sign"></i></div>
                                    <h4>Nos services miniers</h4>
                                </div>
                            </div>
                            <div className="flip-overlay"></div>
                        </div>
                        <div className="square2">
                            <div className="square-container2">
                                <h4>Nos services miniers comprennent :</h4>
                                <ol className="number mb-30">
                                    <li><b>Exploration géologique </b> </li>
                                    <li><b>Évaluation des ressources </b> </li>
                                    <li><b>Développement de projets miniers</b> </li>
                                    <li><b>Exploitation minière</b></li>
                                    <li><b>Valorisation des minerais</b></li>
                                </ol>
                               
                                <a href="single-service/2" className="link-btn" tabIndex="0">En savoir plus</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="square-flip">
                        <div className="square bg-img" style={{ backgroundImage: 'url(assets/img/services/3.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className="square-container d-flex align-items-end justify-content-end">
                                <div className="box-title">
                                    <div><i className="norc-factory"></i></div>
                                    <h4>Nos services de lotissement</h4>
                                </div>
                            </div>
                            <div className="flip-overlay"></div>
                        </div>
                        <div className="square2">
                            <div className="square-container2">
                                <h4>Nos services de lotissement comprennent </h4>
                                <ol className="number mb-30">
                                    <li><b>Bornage</b> </li>
                                    <li><b>Décapage</b> </li>
                                    <li><b>Viabilisation</b> </li>
                                    <li><b>Aménagement</b></li>
                                    <li><b>Commercialisation</b></li>
                                </ol>
                                <a href="single-service/3" className="link-btn" tabIndex="0">En savoir plus</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="square-flip">
                        <div className="square bg-img" style={{ backgroundImage: 'url(assets/img/services/4.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className="square-container d-flex align-items-end justify-content-end">
                                <div className="box-title">
                                    <div><i className="norc-radiation"></i></div>
                                    <h4>Nos services de vente de terrains</h4>
                                </div>
                            </div>
                            <div className="flip-overlay"></div>
                        </div>
                        <div className="square2">
                            <div className="square-container2">
                                <h4>Nos services de vente de terrains comprennent</h4>
                                <ol className="number mb-30">
                                    <li><b>Recherche et sélection de terrains</b> </li>
                                    <li><b>Conseil et expertise</b> </li>
                                    <li><b>Négociation et acquisition</b> </li>
                                    <li><b>Gestion des transactions</b></li>
                                    <li><b>Assistance post-vente</b></li>
                                </ol>
                                <a href="single-service/4" className="link-btn" tabIndex="0">En savoir plus</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="square-flip">
                        <div className="square bg-img" style={{ backgroundImage: 'url(assets/img/services/2.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className="square-container d-flex align-items-end justify-content-end">
                                <div className="box-title">
                                    <div><i className="norc-cogwheel"></i></div>
                                    <h4>Nos services de bail à construction</h4>
                                </div>
                            </div>
                            <div className="flip-overlay"></div>
                        </div>
                        <div className="square2">
                            <div className="square-container2">
                                <h4>Nos services de bail à construction comprennent</h4>
                                <ol className="number mb-30">
                                    <li><b>Conception et réalisation de plans</b> </li>
                                    <li><b>Obtention des permis de construire</b> </li>
                                    <li><b>Sélection des entreprises et artisans</b> </li>
                                    <li><b>Supervision et suivi de chantier</b></li>
                                </ol>
                                <a href="single-service/5" className="link-btn" tabIndex="0">En savoir plus</a>
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
        </div>
    </section>
        </>
    );
};

export default Service;
