import Constant from "../util/constant";

const concaturl = Constant.BASE_URL + Constant.VERSION;

class Service {

    static async getCarousel() {
        try {
            const response = await fetch(concaturl + '/categorie', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': Constant.API_KEY,
                },
            });
            return await response.json();
        } catch (error) {
            return this.handleError(error);
        }
    }

    static async getProjet() {
        try {
            const response = await fetch(concaturl + '/product', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': Constant.API_KEY,
                },
            });
            return await response.json();
        } catch (error) {
            return this.handleError(error);
        }
    }

    static async getBlog() {
        try {
            const response = await fetch(concaturl + '/detail/product/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': Constant.API_KEY,
                },
            });
            return await response.json();
        } catch (error) {
            return this.handleError(error);
        }
    }

    static async sendMail(data) {
        try {
            const response = await fetch(concaturl + '/emails/send', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': Constant.API_KEY,
                },
            });
            return await response.json();
        } catch (error) {
            return this.handleError(error);
        }
    }

    static async visitor(data) {
        try {
            const response = await fetch(concaturl + '/orderdetail', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': Constant.API_KEY,
                },
            });
            return await response.json();
        } catch (error) {
            return this.handleError(error);
        }
    }

    static isEmpty(data) {
        return Object.keys(data).length === 0;
    }

    static handleError(error) {
        console.error(error);
    }

}

export default Service;
