import { useEffect } from "react";
import {NavLink } from "react-router-dom";
import useJQuery from "./useJQuery";



const Navbar = () => {
    //useJQuery()
    useEffect(() => {
        const hidePreloader = () => {
          document.getElementById('preloader').style.display = 'none';
          document.querySelector('.preloader-bg').style.display = 'none';
        };
    
        const timeout = setTimeout(hidePreloader, 1000); // Hide preloader after 3 seconds
    
        window.addEventListener('load', hidePreloader);
    
        // Cleanup event listener and timeout on component unmount
        return () => {
          clearTimeout(timeout);
          window.removeEventListener('load', hidePreloader);
        };
      }, []);

    return (
        <>
    <div className="main-header">
        <div className="header-top">
            <div className="container">
                <div className="top-outer clearfix">
                  
                    <div className="top-left">
                        <ul className="links clearfix">
                            <li><a href="tel:+2250505296326"><span className="fa fa-phone"></span>+225 0505296326 / 0799252562</a></li>
                            <li><a href="mailto:info@minafrikan.com"><span className="fa fa-envelope"></span>info@minafrika.com</a></li>
                            <li><a href="" target="_blank"><span className="fa fa-map-marker"></span> Abidjan, Cocody plateau Dokui, Côte d'Ivoire</a></li>
                        </ul>
                    </div>
                 
                    <div className="top-right clearfix">
                        <ul className="social-icon-one">
                            <li>
                                <a href="#" className="fa fa-whatsapp"></a>
                            </li>
                            <li>
                                <a href="#" className="fa fa-twitter"></a>
                            </li>
                            <li>
                                <a href="#" className="fa fa-instagram"></a>
                            </li>
                            <li>
                                <a href="#" className="fa fa-youtube-play"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <nav className="navbar navbar-expand-md">
        <div className="container">
            <a className="logo" href="/"> <img src="../assets/img/logo.png" alt="MINAFRIKA"/>  <p id="logTitle">MINAFRIKA</p> <p id="ref">La Référence Internationale</p></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span></button>
            <div className="collapse navbar-collapse" id="navbar">
                <ul className="navbar-nav ms-auto">
                <li className="nav-item active"><NavLink reloadDocument className="nav-link" activeclassname='is-active' to="/">Accueil</NavLink></li>
                <li className="nav-item"><NavLink reloadDocument className="nav-link" activeclassname='is-active' to="/about">A propos</NavLink></li>   
                <li className="nav-item"><NavLink reloadDocument className="nav-link" activeclassname='is-active' to="/service">Services</NavLink></li>   
                <li className="nav-item"><NavLink reloadDocument className="nav-link" activeclassname='is-active' to="project">Projets</NavLink></li> 
                <li className="nav-item"><NavLink reloadDocument className="nav-link" activeclassname='is-active' to="/contact-us">Contactez-nous</NavLink></li>
                </ul>
            </div>
        </div>
    </nav>
       
        </>
    );
};

export default Navbar;

