import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import SetFooter from './app/components/setFooter';
import SetNav from './app/components/setNav';
import Home from './app/modules/home/views/Home';
import About from './app/modules/about/views/About';
import Service from './app/modules/service/views/Service';
import SingleService from './app/modules/service/views/SingleService';
import Contact from './app/modules/contact/views/Contact';
import My404Component from './app/modules/erreur/views/404';
import Project from './app/modules/project/views/project';
import SingleProject from './app/modules/project/views/SingleProject';
import { useEffect } from 'react';

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.head.appendChild(script);
  });
};

const useScript = (src, removeOnCleanup = false) => {
  useEffect(() => {
         // Créez et ajoutez le script
         const script = document.createElement('script');
         script.src = src;
         script.async = true;
         document.body.appendChild(script);
    return () => {
      if (removeOnCleanup) {
        // Vérifiez si le script existe avant de le supprimer
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (existingScript) {
          document.body.removeChild(existingScript);
        } else {
          console.log(`Script not found for removal: ${src}`);
        }
      }
  
    };
   
 
  }, [src, removeOnCleanup]);
};




function App() {
  // Utilisation des scripts
// useScript("https://code.jquery.com/jquery-3.6.0.min.js", true);
// useScript("/assets/js/modernizr-2.6.2.min.js", true);
// useScript("/assets/js/imagesloaded.pkgd.min.js", true);
// useScript("/assets/js/jquery.isotope.v3.0.2.js", true);
// useScript("/assets/js/popper.min.js", true);
// useScript("/assets/js/bootstrap.min.js", true);
// useScript("/assets/js/scrollIt.min.js", true);
// useScript("/assets/js/jquery.waypoints.min.js", true);
// useScript("/assets/js/owl.carousel.min.js", true);
// useScript("/assets/js/jquery.stellar.min.js", true);
// useScript("/assets/js/jquery.magnific-popup.js", true);
// useScript("/assets/js/jquery.counterup.min.js", true);
// useScript("/assets/js/YouTubePopUp.js", true);
// useScript("/assets/js/custom.js", true);
useEffect(() => {
  const loadScripts = async () => {
    try {
      await loadScript('https://code.jquery.com/jquery-3.6.0.min.js');
      await loadScript('/assets/js/modernizr-2.6.2.min.js');
      await loadScript('/assets/js/imagesloaded.pkgd.min.js');
      await loadScript('/assets/js/jquery.isotope.v3.0.2.js');
      await loadScript('/assets/js/popper.min.js');
      await loadScript('/assets/js/bootstrap.min.js');
      await loadScript('/assets/js/scrollIt.min.js');
      await loadScript('/assets/js/jquery.waypoints.min.js');
      await loadScript('/assets/js/owl.carousel.min.js');
      await loadScript('/assets/js/jquery.stellar.min.js');
      await loadScript('/assets/js/jquery.magnific-popup.js');
      await loadScript('/assets/js/jquery.counterup.min.js');
      await loadScript('/assets/js/YouTubePopUp.js');
      await loadScript('/assets/js/custom.js');
      console.log('All scripts loaded successfully');
    } catch (error) {
      console.error('Error loading scripts', error);
    }
  };

  loadScripts();
}, []);
  return (

    <Router>
      <>
        <div className="wrapper">
          <SetNav />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/service' element={<Service />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/single-service/:id' element={<SingleService />} />
            <Route  path='/project' element={<Project/>} />
             <Route path='/single-project/:id' element={<SingleProject />} /> 
            <Route path='*' element={<My404Component />} />
          </Routes>
          <SetFooter />
        </div>

      </>
    </Router>

  );
}

export default App;
