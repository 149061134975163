import { useEffect, useState } from "react";
import Preloader from "../../../components/Preloader";
import { useParams } from "react-router-dom";
const SingleProject = () => {
    const { id } = useParams()
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/data/data_proj.json'); // Adjust path as needed
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData.projetDetails && id >= 1 && id <= jsonData.projetDetails.length) {
                    setData(jsonData.projetDetails[id - 1]);
                } else {
                    setError('Invalid id or missing serviceDetails in the data');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]); // Refetch data when `id` changes

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            <Preloader />
            <header className="header slider">
                <div className="owl-carousel owl-theme">
                    {data?.galleryItems?.map((item, index) => (
                        <div
                            key={index} // Provide a unique key for each item
                            className="item bg-img"
                            data-overlay-dark="1"
                            style={{
                                backgroundImage: `url(${item.url})`, // Dynamically set the background image using the item's url
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        ></div>
                    ))}

                </div>
                <div className="arrow bounce text-center">
                    <a href="#" data-scroll-nav="1" className=""> <i className="norc-arrow-down-2"></i> </a>
                </div>
            </header>
            <section className="project-page section-padding bg-gray" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        <div className="section-subtitle">{data?.tsubtitleitle}</div>
                        <div className="section-title">{data?.title}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <p dangerouslySetInnerHTML={{ __html: data.description }} />

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="projects-prev-next">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-sm-flex align-items-center justify-content-between">
                                <div className="projects-prev-next-left">
                                    <a href="#"> <i className="norc-arrow-left"></i> Projet précédent</a>
                                </div>
                                <a href="project"><i className="fa fa-th-large"></i></a>
                                <div className="projects-prev-next-right">
                                    <a href="#">Projet suivant <i className="norc-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SingleProject;
