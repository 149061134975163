import Preloader from "../../../components/Preloader";

const My404Component = () => {
    return (
        <>
        <Preloader />
         <section className="not-found-wrap section-padding bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3 text-center">
                    <div className="box not-found">
                        <div className="text-center">
                            <div className="title-404">404</div>
                            <h3>Page introuvable</h3>
                            <p className="paragraph _404">La page que vous recherchez a été déplacée, supprimée, renommée ou n'a jamais existé.</p>
                            <div><a href="/" className="button-secondary">Allez à l'accueil</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg not-found"></div>
    </section>
        </>
    );
};

export default My404Component;
