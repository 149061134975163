import Preloader from "../../../components/Preloader";

const Project = () => {
    return (
        <>
        <Preloader />
           <section className="banner-header banner-img-top section-padding valign bg-img bg-fixed" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/slider/1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <h6>Dernières réalisations</h6>
                    <h1>Nos <span>Projets</span></h1>
                </div>
            </div>
        </div>
    </section>

      <section className="projects section-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-12 owl-carousel owl-theme">
                    <div className="projects left">
                        <figure><img src="../assets/img/projects/1.jpg" alt="" className="img-fluid"/></figure>
                        <div className="caption">
                            <h4>PASSATION DE MARCHÉ DE PLUSIEURS PROJETS DE CONSTRUCTION D'IMMEUBLES ET DE VILLAS À ABIDJAN <span></span></h4>
                            <p>MARCHÉS DE SOUS-TRAITANCE (CONSTRUCTION CLÉ 🔑 EN MAIN )</p>
                            <div className="line-dec"></div>
                            <div className="info-wrapper">
                                <div className="date"><i className="norc-new-construction"></i> Renovation</div>
                                <div className="more"><a href="single-project/1" className="link-btn" tabindex="0">Découvrir</a></div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </section>
        </>
    );
};

export default Project;
