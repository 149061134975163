import { useEffect, useState } from "react";
import Preloader from "../../../components/Preloader";
import { useParams } from "react-router-dom";

const SingleService = () => {
  const { id } = useParams()
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/data.json'); // Adjust path as needed
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if (jsonData.serviceDetails && id >= 1 && id <= jsonData.serviceDetails.length) {
          setData(jsonData.serviceDetails[id - 1]);
        } else {
          setError('Invalid id or missing serviceDetails in the data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]); // Refetch data when `id` changes

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <Preloader />
      <section className="banner-header banner-img-top section-padding valign bg-img bg-fixed" data-overlay-dark="4" style={{ backgroundImage: 'url(../assets/img/slider/1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h6>Nos Services</h6>
              <h1>détail <span>service</span></h1>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>{data?.title}</h5>
              <p>{data?.description}</p>

              <ol className="number mb-30">
                {data?.services?.map((service, index) => (
                  <li key={index}><b>{service.category} :</b> {service.details}</li>
                ))}
              </ol>
              <p className="mb-30">En plus de nos services BTP, MINAFRIKA propose également un large éventail de services immobiliers, notamment :</p>
              <ol className="number mb-30">
                <li><b>Vente de terrains :</b> Nous disposons d'un vaste portefeuille de terrains à vendre dans des emplacements privilégiés à travers l'Afrique.</li>
                <li><b>Promotion immobilière :</b> Nous développons et commercialisons des projets immobiliers résidentiels et commerciaux de haute qualité.</li>
                <li><b>Gestion immobilière :</b> Nous gérons des propriétés locatives pour le compte de propriétaires individuels et institutionnels.</li>
                <li><b>Investissement immobilier :</b> Nous conseillons nos clients sur les opportunités d'investissement immobilier en Afrique</li>
              </ol>
            </div>
        
            {data?.galleryItems?.map((item, index) => (
                <div className="col-md-6 gallery-item">
              <a href={item.href} title={item.title} className="img-zoom">
                <div className="gallery-box">
                  <div className="gallery-img"> <img src={item.imgSrc} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                </div>
              </a>
            </div>
            ))}
            
          
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleService;