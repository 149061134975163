class Constant {
    static BASE_URL  = "https://email-api.sds-techs.com"
    static PORT  =""
    static VERSION = '/api/v1'
    static END_POINT  = "";
    static API_KEY = "1ab2c3d4e5f61ab2c3d4e5f6";

}

 export default Constant;
