import Carousel from "../../../components/Carousel";
import Preloader from "../../../components/Preloader";


const Home = () => {

    return (
        <>
           <Preloader />
            <Carousel />
            <section className="about section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-30">
                            <div className="section-subtitle">MINE & BTP</div>
                            <div className="section-title"><span>MINAFRIKA</span></div>
                            <p>Un partenaire de confiance pour vos projets immobiliers et miniers en Côte d’Ivoire et partout en Afrique.</p>
                            <p>grâces aux efforts conjugués d’anciens travailleurs hautement qualifiés issus d’imminentes structures MINE ET BTP reconnues au plan national et international.</p>
                            <ul className="listext list-unstyled mb-30">
                                <li>
                                    <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                                    <div className="listext-text">
                                        <p>Plus de 7 ans d'expérience</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                                    <div className="listext-text">
                                        <p>100+ projets exécutés avec succès</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                                    <div className="listext-text">
                                        <p>Qualité exceptionnelle du travail</p>
                                    </div>
                                </li>
                            </ul>
                            <div className="line-dec"></div>
                            {/* <div className="about-bottom"> <img src="assets/img/signature-dark.svg" alt="" className="image about-signature" />
                                <div className="about-name-wrapper">
                                    <div className="about-name">Adam Norman</div>
                                    <div className="about-rol">CEO & Founder</div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            <div className="about-img"> <img src="assets/img/about.jpg" alt="" />
                                <div className="about-img-hotifer">
                                <p>Nos 7 années d'expérience font de nos réalisations des réalisations différentes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    <section className="values section-padding bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-subtitle">Nos Valeurs</div>
                    <div className="section-title">Valeurs <span>fondamentales</span></div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="single-facility">
                        <span className="norc-construction-sign"></span>
                        <h5>Sécurité</h5>
                        <p>L'entreprise respecte les normes de sécurité, une priorité absolue dans les secteurs de la mine et du BTP</p>
                        <div className="facility-shape"> <span className="norc-construction-sign"></span> </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-facility">
                        <span className="norc-bulb-63"></span>
                        <h5>Innovation</h5>
                        <p>Développe des solutions novatrices et améliore la compétitivité dans les secteurs de la mine et du BTP.</p>
                        <div className="facility-shape"> <span className="norc-bulb-63"></span> </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-facility">
                        <span className="norc-paper-diploma"></span>
                        <h5>Qualité</h5>
                        <p>Assure une qualité élevée dans les secteurs de la mine et du BTP, répondant aux normes strictes et aux attentes des clients.</p>
                        <div className="facility-shape"> <span className="norc-paper-diploma"></span> </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-facility">
                        <span className="norc-chess-knight"></span>
                        <h5>Intégrité</h5>
                        <p>Guidant nos actions avec transparence, éthique et engagement envers nos collaborateurs, clients et partenaires.</p>
                        <div className="facility-shape"> <span className="norc-chess-knight"></span> </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-facility">
                        <span className="norc-strategy"></span>
                        <h5>Stratégie</h5>
                        <p>
                        Nous privilégions l'innovation, la qualité et la sécurité pour consolider notre leadership dans les secteurs de la mine et du BTP.</p>
                        <div className="facility-shape"> <span className="norc-strategy"></span> </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-facility">
                        <span className="norc-flag-points-32"></span>
                        <h5>Inclusion</h5>
                        <p>Chez MINAFRIKA, la diversité et l'écoute renforcent notre engagement envers l'équité et le respect mutuel.</p>
                        <div className="facility-shape"> <span className="norc-flag-points-32"></span> </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

     
        </>
    );
};

export default Home;
