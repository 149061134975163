const Carousel = () => {
  return (
    <header className="header slider-fade">
      <div className="owl-carousel owl-theme">
        <div className="text-left item bg-img" data-overlay-dark="4" style={{ backgroundImage: 'url(/assets/img/slider/1.jpg)' }}>
          <div className="v-middle caption">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h4>Haute qualité garantie</h4>
                  <h1>Bienvenue sur le site de MINAFRIKA.</h1>
                  <p>Indrustrie Ivoirienne de Construction Métallique.</p>
                  <a href="/project" className="button-primary">Nos Projets</a>
                  <a href="/service" className="button-tersiyer">Nos Services</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-left item bg-img" data-overlay-dark="4" style={{ backgroundImage: 'url(/assets/img/slider/4.jpg)' }}>
          <div className="v-middle caption">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h4>Architecture Design</h4>
                  <h1>Nous réalisons de grands projets</h1>
                  <p>Nos 7 années d'expérience font de nos réalisations des réalisations différentes.</p>
                  <a href="/project" className="button-primary">Nos Projets</a>
                  <a href="/service" className="button-tersiyer">Nos Services</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-left item bg-img" data-overlay-dark="4" style={{ backgroundImage: 'url(/assets/img/slider/3.png)' }}>
          <div className="v-middle caption">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h4>Equipe professionnelle</h4>
                  <h1>Construisez votre projet de rêve</h1>
                  <p>Nos 7 années d'expérience font de nos réalisations des réalisations différentes.</p>
                  <a href="/project" className="button-primary">Nos Projets</a>
                  <a href="/service" className="button-tersiyer">Nos Services</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Carousel;