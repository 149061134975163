import Preloader from "../../../components/Preloader";

const About = () => {
    return (
        <>
        <Preloader />
        <section className="banner-header banner-img-top section-padding valign bg-img bg-fixed" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/slider/1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <h6>Construction Métallique</h6>
                    <h1>A propos <span>MINAFRIKA</span></h1>
                </div>
            </div>
        </div>
    </section>

    <section className="about section-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-6 mb-30">
                    <h5>MINAFRIKA</h5>
                    <p>
                     est une entreprise dynamique et polyvalente basée en Afrique précisément en Côte d’Ivoire , qui offre une gamme complète de services pour répondre à vos besoins en matière d'immobilier et d'exploitation minière semi-industrielle.</p>
                    <p>Un partenaire de confiance pour vos projets immobiliers et miniers en Côte d’Ivoire et partout en Afrique :</p>
                    <ul className="listext list-unstyled mb-30">
                        <li>
                            <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                            <div className="listext-text">
                                <p>Bail et construction</p>
                            </div>
                        </li>
                        <li>
                            <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                            <div className="listext-text">
                                <p>Lotissement décapage et bornage</p>
                            </div>
                        </li>
                        <li>
                            <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                            <div className="listext-text">
                                <p>Vente de terrain</p>
                            </div>
                        </li>
                        <li>
                            <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                            <div className="listext-text">
                                <p>Recherche minière semi-industriel </p>
                            </div>
                        </li>
                       
                    </ul>
                    <div className="line-dec"></div>
                    <div className="about-bottom"> 
                        <div className="about-name-wrapper">
                            <div className="about-name">MINAFRIKA</div>
                            <div className="about-rol"> est capable de répondre à tous vos besoins grâces à son bureau d’étude et son équipe dynamique très expérimentée.</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="about-img fl-wrap">
									<img src="assets/img/about4.jpg" className="img-fluid" alt=""/>
									<div className="about-img-hotifer color-bg">
										<p>Nos 2 années d'expérience font de nos réalisations des réalisations différentes.</p>
									</div>
								</div>
                </div>
            </div>
        </div>
    </section>


        </>
    );
};

export default About;
