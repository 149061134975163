
const NavbarEmpty = () => {
    return (
        <>         
        </>
    );
};

export default NavbarEmpty;

