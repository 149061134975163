import { useLocation } from "react-router-dom";
import Navbar from "../layouts/nav/NavBar";
import NavbarEmpty from "../layouts/nav/NavBarEmpty";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from 'firebase/analytics'

const SetNav = () => {
    const location =useLocation();
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries
    
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyAuuSuuqeX-W2n0VLTXEayZP2foAg9Ku4M",
      authDomain: "icm-7a63e.firebaseapp.com",
      projectId: "icm-7a63e",
      storageBucket: "icm-7a63e.appspot.com",
      messagingSenderId: "1001927725145",
      appId: "1:1001927725145:web:6c9a6b3db52f5eb4c86148",
      measurementId: "G-D3B3FFSF7X"
    };
    
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'page_view', { page_name:String(location.pathname)});
    
    localStorage.setItem('path',String(location.pathname));
    if(location.pathname.includes("/login")||location.pathname.includes("/register")){
        return (
           <NavbarEmpty/>
        );
    }
    else{
        return (
            <Navbar/>
        );
    }
   

};

export default SetNav;
