import { useState } from "react";
import Service from "../../../core/service/service";
import toast from "react-hot-toast";
import Preloader from "../../../components/Preloader";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    // State to hold form input values
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        objet: '',
        message: '',
        template: 'contact',
        tenant: 'EMAILMINAFRIKA',

    });
   
  
    const handleCaptchaVerification = (value) => {
      if (value) {
        setIsCaptchaVerified(true);
      } else {
        setIsCaptchaVerified(false);
      }
    };
    

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isCaptchaVerified) {
            toast.error("Veuillez vérifier que vous n'êtes pas un robot.");

            return;
          }
        setIsLoading(true);
        // You can perform your form submission logic here
        Service.sendMail(formData).then((response) => {
            toast.success('Votre message a bien été envoyé');
            setFormData({
                name: '',
                email: '',
                contact: '',
                objet: '',
                message: '',  
            });
            setIsLoading(false);
        }).catch((error) => {
            toast.error('Une erreur est survenue');
            setIsLoading(false);
            console.error(error);
        });
    };
    return (
        <>
        <Preloader />
            <section className="banner-header banner-img-top section-padding valign bg-img bg-fixed" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/slider/1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h6>Contactez-Nous</h6>
                            <h1>Contactez- <span>Nous</span></h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact section-padding">
                <div className="container">
                    <div className="row mb-90">
                        <div className="col-md-5 mb-60">
                            <h5>Informations de contact</h5>
                            <p className="mb-30">Si vous avez des projets immobiliers ou miniers en Côte d’Ivoire où en Afrique, n'hésitez pas à contacter MINAFRIKA. Nous serons ravis de vous proposer des solutions personnalisées et de vous accompagner dans la réalisation de vos projets.</p>

                            <div className="contact-link">
                                <div className="contact-link-icon"><span className="norc-phone"></span></div>
                                <div className="contact-link-content">
                                    <div className="contact-link-title">Numéro de téléphone</div>
                                    <div className="contact-link-text">+225 0505296326/0799252562</div>
                                </div>
                            </div>
                            <div className="contact-link">
                                <div className="contact-link-icon"><span className="norc-mail"></span></div>
                                <div className="contact-link-content">
                                    <div className="contact-link-title">Envoyez-nous un email</div>
                                    <div className="contact-link-text">info@minafrika.com</div>
                                </div>
                            </div>
                            <div className="contact-link">
                                <div className="contact-link-icon"><span className="norc-square-pin"></span></div>
                                <div className="contact-link-content">
                                    <div className="contact-link-title">Visitez nos bureaux</div>
                                    <div className="contact-link-text">Cocody plateau Dokui</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-2">
                            <div className="form-wrap">
                                <div className="form-box">
                                    <h5>Prendre contact</h5>
                                    <form onSubmit={handleSubmit} className="contact__form">
                                        <div className="row">
                                            <div className="col-12">

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 form-group">
                                                <input name="name" type="text" placeholder="Votre nom *" required
                                                    value={formData.name}
                                                    onChange={handleInputChange} />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="email" type="email" placeholder="Votre Email *"
                                                    required
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="contact" type="text" placeholder="Votre contact *" required
                                                    value={formData.contact}
                                                    onChange={handleInputChange} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <input name="objet" type="text" placeholder="Sujet *" required
                                                    value={formData.objet}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <textarea name="message" id="message" cols="30" rows="4" placeholder="Message *" required
                                                    value={formData.message}
                                                    onChange={handleInputChange}></textarea>
                                            </div>
                                            
                                            <div className="col-md-12">
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                                onChange={handleCaptchaVerification}/>
                                                {isLoading ? (
                                                    <button className="button-secondary mt-15 disabled">
                                                        <i className="fa fa-spinner fa-spin"></i><span>Envoyer</span>
                                                    </button>
                                                ) : (
                                                    <button type="submit" className="button-secondary mt-15" disabled={!isCaptchaVerified}><span>Envoyer</span>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </>
    );
};

export default Contact;
